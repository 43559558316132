import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Icegram = () => {
  const headingbanner = {
    title: `Icegram`,
    content: `An Email Marketing Tool`,
  };

  const data = {
    images: ["icegram.webp", "icegram-2.webp"],
    firstAlt: "UI UX Screen to get an overview of the email marketing campaign's performance. ",
    firstTitle: "Campaign Performance",
    secondAlt: "In-built email marketing templates and an option to create customised templates.",
    secondTitle: "E-mail Marketing Templates",
  };
  
  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing"],
      },
      {
        title: "Platform",
        text: ["WordPress Plugin"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing web application suffered from poor user experience due to cluttered interface and lack of clear hierarchy.`,
          `Users struggled to navigate through the tool, find relevant information, and complete tasks efficiently.`,
          `The complex layout and overwhelming visual elements hindered the usability and effectiveness of the tool, leading to user frustration and decreased productivity.`,
          `Therefore, there was a need to redesign the web plugin tool, focusing on decluttering the interface, establishing a clear visual hierarchy, and improving overall workflows to enhance user satisfaction and task completion rates.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our goal was to redesign the existing web plugin tool to address the usability issues and create an intuitive user experience by understanding the existing workflows & pain points.`,
          `The solution involved implementing a clear and logical information hierarchy, as well as decluttering the interface to provide users with a seamless and efficient workflow.`,
          `By simplifying the layout, organizing content effectively, and strategically correcting the workflows, the new design enhanced user engagement, improved task completion rates, and increased overall user satisfaction.`,
          `Our solution aimed to create a user-centric interface that allowed users to easily navigate, find relevant information, and accomplish their tasks with ease, resulting in a more streamlined and effective WordPress plugin tool.`
        ],
      },
    ],
    image: ["icegram-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Icegram is a Wordpress plugin for Email Marketing`,
    para: [
      `With this tool users can easily design and build email campaigns using customizable templates or a drag-and-drop editor.`,
      `Users can set up automated email notifications to be sent to subscribers based on specific triggers, such as new blog posts or product updates.`,
      `Additionally, one-time broadcast emails can be sent to entire subscriber list or specific segments for time-sensitive announcements.`
    ],
    content: {
      imageAlt: "UI UX Screen to get an overview of the email marketing campaign's performance.",
      imageTitle: 'Campaign Performance',
      image: ["icegram-content-1.webp"],
      para: [
        `This screen gives overview of insights about analytics and performance reports for each email campaign, including KPI's like open rates, click-through rates, unsubscribe rate, bounce rate, and conversion metrics etc.`,
        `This helps users to analyze campaign performance and audience behaviour to gain valuable insights for optimizing future email marketing strategies.`,
        `It also shows list of all the ongoing campaings & their statuses which helps users to keep track of their email campaigns.`
      ],
    },
    content2: {
      imageAlt: "In-built email marketing templates and an option to create customised templates.",
      imageTitle: 'E-mail Marketing Templates',
      image: ["icegram-content-2.webp"],
      para: [
        `Users can access a library of pre-designed email templates to streamline campaign creation.`,
        `Users can modify these templates as per their need or even create & save their own templates for their campaigns.`,
      ],
    },
  };
  const projectData2 = {
    title: `Customise <br /> Email Templates`,
    para: [
     `Apart from the classic editor user can now use new and more efficient Drag & Drop Editor for customizing their email templates.`,
     `The new drag & drop editor is very user friendly and contains variety of customizable elements and layouts.`
    ],
    content: {
      image: ["icegram-content-3.webp"],
      imageAlt: 'UI Screen to design customised email templates',
      imageTitle: 'Customise E-mail Templates',
    },
  };
  const projectData3 = {
    title: `Schedule & publish Campaigns Easily`,
    para: [
      `This screen includes all the details of the campaign which is to be filled by the user before launching any campaign and shows a preview of the email to the user.`,
      `It includes information like Sender and Recipients email details, allows logical control over the list of users that will receive the campaign email, allows scheduling of the campaigns as per desired frequency of date & time to automate the process of post notification emails.`,
      `It also includes Email Authentication & SPAM Compliance feature to ensure email compliance with anti-spam regulations and monitor email deliverability rates with some tracking options.`
    ],
    content: {
      image: [
        "icegram-last-1.webp",
        "icegram-last-2.webp",
        "icegram-last-3.webp",
        "icegram-last-4.webp",
        "icegram-last-5.webp",
      ],
      firstAlt: 'Email Scheduling Options while creating a new campaign',
      firstTitle: 'Schedule E-mails',
      secondAlt: 'Decide on the font style, colour, size, etc. while customising an email template',
      secondTitle: "Customise Template's Font",
      thirdAlt: 'UI Screen to change image of the email template',
      thirdTitle: "Customise Template's Image",
      fourthAlt: 'UI UX Screen to add campaign details and publish it',
      fourthTitle: 'Preview & Publish Campaign',
      fifthAlt: 'Inline editor to change the template style',
      fifthTitle: 'Inline Editor',
    },
  };
  const conclusionData = [
    {
      para: [
        `We created this platform for Icegram, for their email marketing tool. Their main aim was to create email campaigns, analyse the data and gain insights into user behaviours to maximise engagement and reach.`,
        `They earlier had a wordpress default interface to create, manage and analyse the campaigns and its insights which did not have very user friendly interface and lacked efficient workflows.`,
        `We transformed their campaign's workflow into a user friendly and intuitive interface which resulted in maximised efficiency, better campaign analysis, and a competitive edge in the market.`,
        `Now, Icegram can fully leverage their email marketing potential and provide exceptional value to their customers.`
      ],
    },
  ];
  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={7} />
      </div>
    </Layout>
  );
};

export default Icegram;

export const Head = () => (
  <Seo title="Icegram- Email Marketing Tool Case Study | Octet " description="A WordPress plugin tool where users can customise the templates according to their email campaigns. We conducted User Research and did UI UX Designing for them." />
)